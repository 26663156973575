import React from "react"
import "@styles/404.css";
import "@styles/styles.css";
import MainHeader from "@components/MainHeader/";

export default function Page404() {
  return (
    <div id="error404" className="error404">
      <MainHeader></MainHeader>
      <div className="content">
        <h1 className="goldText">404</h1>
        <p>go home, buddy!</p>
        <a class="button" href="/">
          <div class="inset blue">
            <span>Home</span>
          </div>
        </a>
      </div>
    </div>
  )
}
